// TODO: Add correct values for all "domain.tld", "-placeholder" fields and
// manifest properties.

module.exports = {
  siteUrl: "https://domain.tld",
  siteLang: "el-gr", // This should be the same as Prismic master locale
  title: "title-placeholder",
  shortTitle: "short-title-placeholder",
  description: "description-placeholder",
  logoUrl: "https://domain.tld/images/logo.png",

  // Values used in manifest
  siteBackgroundColor: "#fff",
  siteThemeColor: "#000",
  siteIcon: "./src/images/icon.png",

  // Image Details used as fallback at metadata
  defaultImage: "https://domain.tld/images/logo.png",
  defaultImageWidth: "512",
  defaultImageHeight: "512",
  defaultImageAlt: "image-alt-placeholder",
};
